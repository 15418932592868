<script lang="ts">
  import Icon from '@iconify/svelte'
  import { Dropdown, DropdownItem } from 'flowbite-svelte'
  import { notifications, user } from '@stores/user'
  import { useDateTimeTranslations, useTranslations } from '@i18n/utils'
  import { sendNotificationAnalytic } from '@utils/fetchUser'

  export let lang

  const t = useTranslations(lang)
  const dt = useDateTimeTranslations(lang)

  let unreadCount = $notifications.unreadCount
  $: data = $notifications.data

  function readAllNotifications() {
    notifications.subscribe(notifications => {
      notifications.data = notifications.data?.map(d => ({
        ...d,
        isMessageRead: true
      }))

      notifications.unreadCount = notifications.data!.filter(d => !d.isMessageRead).length
    })

    unreadCount = $notifications.unreadCount
    data = [...$notifications.data!]

    sendNotificationAnalytic('READ')
  }
</script>

{#if $user && $notifications}
  <button
    type="button"
    on:click={() =>
      setTimeout(() => {
        readAllNotifications()
      }, 1000)}
    id="notification-bell"
    aria-labelledby="notification-dropdown"
    class={`relative inline-flex size-12 items-center text-brandBlue  duration-300 ${unreadCount === 0 ? 'hover:text-brandOrange' : 'hover:text-indigo-700'}`}
  >
    <Icon
      icon="mdi:bell"
      class="mx-auto size-6"
    />
    {#if unreadCount > 0}
      <div class="absolute right-3 top-3 inline-flex size-3 rounded-full border-2 border-white bg-brandOrange" />
    {/if}
  </button>
  <Dropdown
    id="notification-dropdown"
    triggeredBy="#notification-bell"
    class=" max-w-sm divide-y divide-indigo-100 rounded shadow lg:w-[24rem]"
    headerClass="bg-brandBlue/10 rounded-t"
  >
    <div
      slot="header"
      class="px-4 py-3"
    >
      {t('nav.notification')}
      {#if unreadCount > 0}
        <span
          class="inline-flex size-5 items-center justify-center rounded-full bg-brandOrange text-sm font-medium text-white"
        >
          {unreadCount}
        </span>
      {/if}
    </div>

    {#if data}
      {#each data as d}
        <DropdownItem
          href={d.links}
          class="hover:bg-slate-50"
          on:click={() => sendNotificationAnalytic('CLICK', d.id)}
        >
          <div class="flex flex-row gap-x-1">
            <h5 class="text-sm font-semibold text-slate-900">{d.title}</h5>

            {#if !d.isMessageRead}
              <span class="mr-2 rounded bg-brandBlue px-2.5 py-0.5 text-xs font-medium text-white"
                >{t('nav.notification.new')}</span
              >
            {/if}
          </div>
          <p class="py-1 text-sm text-slate-600">{d.message}</p>
          <time
            datetime={dt({ datetime: d.timestamp })}
            class="text-xs text-brandBlue">{dt({ datetime: d.timestamp, format: 'll' })}</time
          >
        </DropdownItem>
      {/each}
    {/if}
  </Dropdown>
{:else}
  <div class="relative inline-flex size-8 items-center lg:size-12">
    <Icon
      icon="mdi:bell-outline"
      class="mx-auto size-6 text-slate-400 lg:h-6 lg:w-6"
    />
  </div>
{/if}
